import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";

import { CurrentUserContext } from "../../providers/auth";

import Layout from "../../components/layout";
import { ErrMsg } from "../../components/message";
import ConfirmBox from "../../components/confirmBox";
import UserUtil from "../../utils/user";
import Links, { getAdminLinks } from "../../utils/links";
import Services, {
    isUnauthorizedError,
    isForbiddenError
} from "../../services";
import Loading from "../../components/loading";
import Tabs from "../../components/tabs";

const LamppostsPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.Admin}
            seoTitle="Lamppost Management"
            permissionValidator={UserUtil.hasSysAdminPrivileges}
            forceCheckSessionCount={checkCount}
        >
            <Lampposts forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default LamppostsPage;

const Lampposts = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const lamppostService = Services(currentUser).lamppost;

    const [lampposts, setLampposts] = useState([]);
    const [editLamppost, setEditLamppost] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    // addOrEdit: true = Add lamppost, false = Edit lamppost
    const [addOrEdit, setAddOrEdit] = useState(true);
    const [districts, setDistricts] = useState([]);
    const [delLamppost, setDelLamppost] = useState(null);
    const [showConfirmDel, setShowConfirmDel] = useState(false);
    const [delErr, setDelErr] = useState("");
    const [loading, setLoading] = useState(true);

    const handleAuthError = (err) => {
        if (isUnauthorizedError(err) || isForbiddenError(err)) {
            forceCheckSession();
        }
    };

    const reloadLampposts = () => {
        setShowConfirmDel(false);
        setShowEdit(false);
        setLoading(true);
        lamppostService
            .getLampposts()
            .then((d) => {
                setLampposts(d.lampposts);
            })
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let isMount = false;

    const deleteLamppost = () => {
        lamppostService
            .deleteLamppost(delLamppost.lamppost_id)
            .then(() => reloadLampposts())
            .catch((err) => {
                handleAuthError(err);
                if (err && err.code === "LAMPPOST_IN_USE") {
                    setDelErr(
                        "Cannot delete this lamppost: There are sensor data records."
                    );
                } else {
                    setDelErr(
                        `Cannot delete this lamppost${
                            err && err.message && ": " + err.message
                        }.`
                    );
                }
                setTimeout(() => isMount && setDelErr(""), 5000);
                setShowConfirmDel(false);
            });
    };

    useEffect(() => {
        isMount = true;
        return () => {
            isMount = false;
        };
    }, []);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        lamppostService
            .getLampposts()
            .then((d) => {
                setLampposts(d.lampposts);
                setLoading(false);
            })
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
            });
        lamppostService
            .getDistricts()
            .then((d) => setDistricts(d.districts))
            .catch((err) => {
                handleAuthError(err);
                console.error(err);
            });
    }, [currentUser]);

    const LamppostsTable = () => (
        <table className="w-full admin-table">
            <thead>
                <tr>
                    <th style={{ width: "8%" }}>Lamppost ID</th>
                    <th style={{ width: "14%" }}>Location (Eng)</th>
                    <th style={{ width: "11%" }}>Location (繁)</th>
                    <th style={{ width: "11%" }}>Location (简)</th>
                    <th style={{ width: "11%" }}>District</th>
                    <th style={{ width: "10%" }}>Latitude</th>
                    <th style={{ width: "10%" }}>Longitude</th>
                    <th style={{ width: "5%" }}>Enabled</th>
                    <th style={{ width: "20%" }}>Actions</th>
                </tr>
            </thead>
            <tbody className="break-all">
                {lampposts.map((l, idx) => (
                    <tr key={idx}>
                        <td>{l.lamppost_id}</td>
                        <td>{l.location_en}</td>
                        <td>{l.location_tc}</td>
                        <td>{l.location_sc}</td>
                        <td>{l.district_en}</td>
                        <td>{l.latitude}</td>
                        <td>{l.longitude}</td>
                        <td>{l.available ? "Yes" : "No"}</td>
                        <td>
                            <button
                                className="btn-secondary mt-1 mr-3"
                                onClick={() => {
                                    setAddOrEdit(false);
                                    // convert latitude & longitude to string for text input
                                    setEditLamppost(
                                        Object.assign({}, l, {
                                            latitude: "" + l.latitude,
                                            longitude: "" + l.longitude
                                        })
                                    );
                                    setShowEdit(true);
                                }}
                            >
                                Edit
                            </button>
                            <button
                                className="btn-secondary mt-1"
                                onClick={() => {
                                    setDelErr("");
                                    setDelLamppost(l);
                                    setShowConfirmDel(true);
                                }}
                            >
                                Delete
                            </button>
                            {delErr &&
                                delLamppost.lamppost_id === l.lamppost_id && (
                                    <ErrMsg msg={delErr} />
                                )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const ConfirmDel = () => (
        <ConfirmBox
            action={deleteLamppost}
            cancelAction={() => setShowConfirmDel(false)}
            deleteBtn={true}
        >
            Are you sure to delete lamppost{" "}
            <span className="inline-block font-medium">
                {delLamppost.location_en} ({delLamppost.location_tc})
            </span>{" "}
            ?
        </ConfirmBox>
    );

    return (
        <>
            <Tabs links={getAdminLinks(Links.Lampposts)} />
            {districts.length !== 0 && showEdit && (
                <EditLamppost
                    addOrEdit={addOrEdit}
                    lamppost={editLamppost}
                    setLamppost={setEditLamppost}
                    setShowEdit={setShowEdit}
                    districts={districts}
                    lamppostService={lamppostService}
                    reloadLampposts={reloadLampposts}
                    onError={handleAuthError}
                />
            )}
            <div className={showEdit ? "hidden" : ""}>
                <div className="text-right my-3">
                    <button
                        className="btn-primary"
                        onClick={() => {
                            setAddOrEdit(true);
                            setEditLamppost({
                                available: true,
                                district_id: districts[0].district_id
                            });
                            setShowEdit(true);
                        }}
                        disabled={districts.length === 0}
                    >
                        Add Lamppost
                    </button>
                </div>
                {loading ? <Loading /> : <LamppostsTable />}
            </div>
            {showConfirmDel && <ConfirmDel />}
        </>
    );
};

Lampposts.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const EditLamppost = ({
    addOrEdit,
    lamppost,
    setLamppost,
    setShowEdit,
    districts,
    lamppostService,
    reloadLampposts,
    onError
}) => {
    const [lamppostErr, setLamppostErr] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [updateErr, setUpdateErr] = useState("");

    const copyLamppost = (updatedProps) =>
        Object.assign({}, lamppost, updatedProps);
    const updateLamppostProp = (updatedProp = {}) =>
        setLamppost(copyLamppost(updatedProp));

    const checkLamppost = () => {
        let hasErr = false;
        let newErr = [
            "lamppost_id",
            "location_en",
            "location_tc",
            "location_sc",
            "latitude",
            "longitude"
        ].reduce((err, prop) => {
            if (
                ("" + lamppost[prop]).trim() === "" ||
                lamppost[prop] === undefined
            ) {
                err[prop] = true;
                hasErr = true;
            }
            return err;
        }, {});
        if (isNaN(Number(lamppost.latitude))) {
            newErr["latitude"] = true;
            hasErr = true;
        }
        if (isNaN(Number(lamppost.longitude))) {
            newErr["longitude"] = true;
            hasErr = true;
        }
        setLamppostErr(newErr);
        return hasErr;
    };

    const confirmUpdate = () => {
        setUpdateErr("");
        if (checkLamppost()) {
            return;
        }
        setShowConfirm(true);
    };

    const updateLamppost = () => {
        const updateFunc = addOrEdit
            ? lamppostService.saveLamppost
            : lamppostService.updateLamppost;
        // trim space and convert latitude & longitude to number
        updateFunc(
            Object.assign({}, lamppost, {
                lamppost_id: lamppost.lamppost_id.trim(),
                location_en: lamppost.location_en.trim(),
                location_tc: lamppost.location_tc.trim(),
                location_sc: lamppost.location_sc.trim(),
                latitude: Number(lamppost.latitude),
                longitude: Number(lamppost.longitude)
            })
        )
            .then(() => {
                setShowConfirm(false);
                reloadLampposts();
            })
            .catch((err) => {
                if (err && err.code === "DUPLICATED_LAMPPOST") {
                    setUpdateErr(
                        "Duplicated lamppost: Please use unique Lamppost ID, Location (Eng), Location (繁) and Location (简) among all lampposts."
                    );
                } else {
                    setUpdateErr(
                        `Cannot ${addOrEdit ? "add" : "update"} the lamppost${
                            err && err.message && ": " + err.message
                        }`
                    );
                }
                setShowConfirm(false);
                onError(err);
            });
    };

    const close = () => setShowEdit(false);

    const ConfirmUpdate = () => (
        <ConfirmBox
            action={updateLamppost}
            cancelAction={() => setShowConfirm(false)}
        >
            Are you sure to {addOrEdit ? "add" : "update"} lamppost{" "}
            {lamppost.lamppost_id}?
        </ConfirmBox>
    );

    return (
        <>
            <div className="mt-6 flex w-3/4">
                <div className="ml-auto w-2/3 title-h2">
                    {addOrEdit
                        ? "Add Lamppost"
                        : "Edit Lamppost " + lamppost.lamppost_id}
                </div>
            </div>
            {addOrEdit && (
                <div className="flex w-3/4 mt-4">
                    <div className="w-1/3 label-r">Lamppost ID</div>
                    <div className="w-2/3">
                        <input
                            className="input"
                            onChange={(e) =>
                                updateLamppostProp({
                                    lamppost_id: e.target.value
                                })
                            }
                            value={lamppost.lamppost_id || ""}
                        ></input>
                        <ErrMsg
                            msg={
                                lamppostErr.lamppost_id &&
                                "Lamppost ID is required."
                            }
                        />
                    </div>
                </div>
            )}
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3 label-r">Location (Eng)</div>
                <div className="w-2/3">
                    <input
                        className="input"
                        onChange={(e) =>
                            updateLamppostProp({ location_en: e.target.value })
                        }
                        value={lamppost.location_en || ""}
                    ></input>
                    <ErrMsg
                        msg={
                            lamppostErr.location_en &&
                            "Location (Eng) is required."
                        }
                    />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3 label-r">Location (繁)</div>
                <div className="w-2/3">
                    <input
                        className="input"
                        onChange={(e) =>
                            updateLamppostProp({ location_tc: e.target.value })
                        }
                        value={lamppost.location_tc || ""}
                    ></input>
                    <ErrMsg
                        msg={
                            lamppostErr.location_tc &&
                            "Location (繁) is required."
                        }
                    />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3 label-r">Location (简)</div>
                <div className="w-2/3">
                    <input
                        className="input"
                        onChange={(e) =>
                            updateLamppostProp({ location_sc: e.target.value })
                        }
                        value={lamppost.location_sc || ""}
                    ></input>
                    <ErrMsg
                        msg={
                            lamppostErr.location_sc &&
                            "Location (简) is required."
                        }
                    />
                </div>
            </div>

            <div className="flex items-center w-3/4 mt-4">
                <div className="w-1/3 label-r">District</div>
                <div className="w-2/3">
                    <select
                        className="input-inline w-full"
                        value={lamppost.district_id}
                        onChange={(e) =>
                            updateLamppostProp({ district_id: e.target.value })
                        }
                    >
                        {districts.map((d, idx) => {
                            return (
                                <option key={idx} value={d.district_id}>
                                    {d.name_en}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3 label-r">Latitude</div>
                <div className="w-2/3">
                    <input
                        className="input"
                        onChange={(e) =>
                            updateLamppostProp({ latitude: e.target.value })
                        }
                        value={lamppost.latitude || ""}
                    ></input>
                    <ErrMsg
                        msg={
                            lamppostErr.latitude &&
                            "Latitude should be a number."
                        }
                    />
                </div>
            </div>
            <div className="flex w-3/4 mt-4">
                <div className="w-1/3 label-r">Longitude</div>
                <div className="w-2/3">
                    <input
                        className="input"
                        onChange={(e) =>
                            updateLamppostProp({ longitude: e.target.value })
                        }
                        value={lamppost.longitude || ""}
                    ></input>
                    <ErrMsg
                        msg={
                            lamppostErr.longitude &&
                            "Longitude should be a number."
                        }
                    />
                </div>
            </div>
            <div className="flex items-center w-3/4 mt-4">
                <div className="w-1/3 label-r">Enabled</div>
                <div className="w-2/3">
                    <label className="mr-8">
                        <input
                            className="mr-1"
                            name="lamppost-avail"
                            type="radio"
                            checked={lamppost.available}
                            onChange={() =>
                                updateLamppostProp({ available: true })
                            }
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            className="mr-1"
                            name="lamppost-avail"
                            type="radio"
                            checked={!lamppost.available}
                            onChange={() =>
                                updateLamppostProp({ available: false })
                            }
                        />
                        No
                    </label>
                </div>
            </div>

            <div className="flex w-3/4 mt-4 pb-12">
                <div className="w-1/3"></div>
                <div className="w-2/3">
                    <button
                        className="btn btn-primary w-1/3 mr-4"
                        onClick={confirmUpdate}
                    >
                        {addOrEdit ? "Add" : "Update"}
                    </button>
                    <button className="btn btn-secondary w-1/3" onClick={close}>
                        Cancel
                    </button>
                    <ErrMsg msg={updateErr} />
                </div>
            </div>
            {showConfirm && <ConfirmUpdate />}
        </>
    );
};

EditLamppost.propTypes = {
    addOrEdit: PropTypes.bool,
    lamppost: PropTypes.shape({
        lamppost_id: PropTypes.string,
        location_en: PropTypes.string,
        location_tc: PropTypes.string,
        location_sc: PropTypes.string,
        latitude: PropTypes.string,
        longitude: PropTypes.string,
        district_id: PropTypes.string,
        available: PropTypes.bool
    }),
    setLamppost: PropTypes.func,
    setShowEdit: PropTypes.func,
    districts: PropTypes.arrayOf(
        PropTypes.shape({
            district_id: PropTypes.string,
            name_en: PropTypes.string,
            name_tc: PropTypes.string,
            name_sc: PropTypes.string
        })
    ),
    lamppostService: PropTypes.object
};
